import moment from 'moment';
import Utils from '../../../../client/helpers/Utils';

class ProductValidationService {

	static isSerialNumberValid = (serialNumber) => {
		const regExSerialNumberEndUser = /SY[0-9]{10}$/;
		const regExSerialNumberVMWare = /\VMware-/;
		if (serialNumber === null || serialNumber === undefined) {
			return false;
		} else {
			return regExSerialNumberEndUser.test(serialNumber) || regExSerialNumberVMWare.test(serialNumber);
		}
	};

	static hasSerialNumber(product) {
		if (!product.activationCode && !product.serialNumber && !product.virtualAppliance) {
			return false;
		}

		return true;
	}

	static isDurationValid = (maxMaintenanceExtension, totalDuration) => {
		return totalDuration <= maxMaintenanceExtension;

	};

	static isSelectActivationDateInFuture = (values) => {
		let hasWarnings = false;
		let today = moment();
		let activationDate = values && moment(values.activationDate, 'DD/MM/YYYY');

		if (activationDate > today) {
			hasWarnings = true;
		}

		return hasWarnings;
	};

	static validateProductModel = (product) => {
		return !!product?.modelId;
	};

	static validateSelectOptionsRenewal = (value) => {
		let hasErrors = false;
		if (!value || !value.length) {
			hasErrors = true;
		}

		return hasErrors;
	};

	static validateSelectOptionsDuration = (values) => {
		let hasErrors = false;

		if (values && values.maintenance) {
			if (!values.maintenance.duration || values.maintenance.duration === 'Select a duration') {
				hasErrors = true;
			}
		}

		return hasErrors;
	};

	static validateMaintenanceServices = (product) => {
		let errors = [];

		if (product?.maintenanceServices) {
			errors = product.maintenanceServices.map((maintenanceService) => {
				let error = {};

				if (!maintenanceService.maintenancePlanId) {
					error.maintenancePlanId = 'Cannot be empty';
				}

				if (!maintenanceService.duration) {
					error.duration = 'Cannot be empty';
				}

				if (!maintenanceService.quoteNumber) {
					error.quoteNumber = 'Cannot be empty';
				}

				if (maintenanceService.saleValue === undefined || maintenanceService.saleValue === null) {
					error.saleValue = 'Cannot be empty';
				} else if (!ProductValidationService.validateSaleValue(maintenanceService.saleValue)) {
					error.saleValue = 'Should look like this:\n' + '4000  1456.43  1456,43 or 1.456,43';
				}

				return error;
			});
		}

		return errors;
	};

	static validateAddons = (product) => {
		let errors = [];

		if (product && product.productAddons) {
			errors = product.productAddons.map((addon) => {
				let error = {};

				if (!addon.addonId) {
					error.addonId = 'Cannot be empty';
				}

				if (!addon.startDate && !addon.startOnActivation) {
					error.startDate = 'Cannot be empty';
				}

				if (!addon.duration) {
					error.duration = 'Cannot be empty';
				}

				return error;
			});
		}

		return errors;
	};

	static validateEndUser = (product) => {
		return product.virtualAppliance || !product.activationDate || !!product?.userId;
	};

	static validateActivationDate = (product) => {
		let regExp = new RegExp(/^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/);
		let activationDate = product?.activationDate;

		if (!regExp.test(activationDate)) {
			return false;
		}

		return true;
	};

	static validateNotesField(product) {
		return !product.virtualAppliance || !!product.notes;
	}

	static validateSaleValue = (saleValue) => {
		let normSaleValue = Utils.normalizeSaleValue(saleValue);
		let regExp = new RegExp(/^(\d+)(\.\d{1,2})?$/);

		if (saleValue === undefined || saleValue === null || saleValue === '') {
			return true;
		}

		if (!regExp.test(normSaleValue)) {
			return false;
		}

		return true;
	};

	static warn = (product) => {
		let warnings = {};

		if (ProductValidationService.isSelectActivationDateInFuture(product)) {
			warnings.activationDate = 'Selected date is in the future';
		}

		return warnings;
	};

	static validate = (product) => {
		let errors = {
			modelId: !ProductValidationService.validateProductModel(product) ? 'Cannot be empty' : null,
			userId: !ProductValidationService.validateEndUser(product) ? 'Please select end-user.' : null,
			notes: !ProductValidationService.validateNotesField(product) ? 'Cannot be empty' : null,
			saleValue: !ProductValidationService.validateSaleValue(product?.saleValue) ? 'Should look like this:\n' + '4000  1456.43  1456,43 or 1.456,43' : null,
			productAddons: ProductValidationService.validateAddons(product),
			maintenanceServices: ProductValidationService.validateMaintenanceServices(product),
			serialNumber: !ProductValidationService.hasSerialNumber(product) ? 'Cannot be empty' : null,
			activationDate: (!product.virtualAppliance && product.userId !== '' && product.userId !== null && !product.activationDate) ? 'Please select activation date.' : (!product.virtualAppliance && product.userId !== '' && product.userId !== null && !ProductValidationService.validateActivationDate(product)) ? 'Invalid activation date.' : null,
		};

		return errors;
	};
}

export default ProductValidationService;
