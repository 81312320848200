import Utils from '../../helpers/Utils';
import * as actionTypes from './actionTypes';

// const DEFAULT_PAGEINATION = {
// 	currentPage: 1,
// 	itemsPerPage: 15,
// 	numberOfPages: 1,
// 	totalItems: 1
// };

export const INITIAL_STATE = {
	status: null,
	loading: false,
	error: null,
	products: [],
	pagination: {},
	selectedProduct: null,
	activationKey: null
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.START_FETCHING_PRODUCTS:
			return {
				...INITIAL_STATE,
				status: 'loading',
				loading: true
			};
		case actionTypes.GET_PRODUCTS_SUCCESS: {
			return {
				...state,
				status: 'success',
				loading: false,
				products: action.payload.products,
				pagination: action.payload.pagination
			};
		}
		case actionTypes.GET_PRODUCTS_ERROR: {
			return {
				...state,
				status: 'failed',
				error: action.payload,
				loading: false
			};
		}
		case actionTypes.GET_PRODUCT_SUCCESS: {
			return {
				...state,
				status: 'success',
				loading: false,
				selectedProduct: {
					...action.payload,
					productAddons: action.payload.productAddons.map((addon) => { return { ...addon, uuid: Utils.uuidv4() }; }),
					maintenanceServices: action.payload.maintenanceServices.map((maintenanceService) => { return { ...maintenanceService, uuid: Utils.uuidv4() }; })
				},
			};
		}
		case actionTypes.GET_PRODUCT_ERROR: {
			return {
				...state,
				status: 'failed',
				loading: false,
				selectedProduct: null,
				error: action.payload
			};
		}
		case actionTypes.RESET_PRODUCT: {
			return {
				...state,
				loading: false,
				selectedProduct: null,
			};
		}
		case actionTypes.DELETE_PRODUCT_FOR_USER_SUCCESS:
			return {
				...state,
				status: action.payload
			};
		case actionTypes.DELETE_PRODUCT_FOR_USER_ERROR:
			return {
				...state,
				error: action.payload.message
			};
		case actionTypes.GET_ACTIVATION_CODE_SUCCESS:
			return {
				...state,
				activationKey: action.payload
			};
		case actionTypes.GET_ACTIVATION_CODE_ERROR:
			return {
				...state,
				error: action.payload
			};
		default:
			return state;
	}
};
