import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button, Icon, Tooltip, Modal } from '@syneto/compass-react';
import Form from '../../../../components/forms/form';
import SelectOptionsRenderer from '../../../../helpers/SelectOptionsRenderer';
import { getActivationCode, getProductSuccess } from '../../../../data/product/actions';
import ProductAddon from './ProductAddon';
import Utils from '../../../../helpers/Utils';
import ProductMaintenancePlan from './ProductMaintenancePlan';
import { WarningModal } from './WarningModal';
import ProductValidationService from '../../validation/ProductValidationService';
import ExpiredProductAddons from './ExpiredProductAddons';
import { getSettingValue } from '../../../../helpers/settings';

const defaultAddon = () => {
	return {
		uuid: Utils.uuidv4(),
		addonId: '',
		duration: '',
		durationType: 'months',
		addonType: 'Time-based',
		startDate: null,
		startOnActivation: false,
		tierId: ''
	};
};

const ProductForm = (props) => {
	const {
		productModels,
		companiesWithEndUsers,
		maintenancePlansOptions,
		id,
		formName,
		isEditing,
		isSendingRequest,
		generatedActivationKey,
		getActivationCode,
		changeFormFieldInState,
		submit,
		handleSubmit,
		product,
		initialValues,
		maintenanceTotalDuration,
		maxMaintenanceExtension
	} = props;
	const [virtualAppliance, setVirtualAppliance] = useState(null);
	const [productAddons, setProductAddons] = useState(product?.productAddons ?? []);
	const [expiredProductAddons, setExpiredProductAddons] = useState([]);
	const [showExpiredAddons, setShowExpiredAddons] = useState(false);
	const [maintenanceServices, setMaintenanceServices] = useState(product?.maintenanceServices ?? initialValues.maintenanceServices);
	const [validationMessage, setValidationMessage] = useState(null);

	useEffect(() => {
		if (virtualAppliance) {
			changeFormFieldInState('virtualAppliance', true);
			if (!product) {
				changeFormFieldInState('serialNumber', null);
			}
			if (!product?.activationCode) {
				getActivationCode();
			}
		} else {
			changeFormFieldInState('virtualAppliance', false);
		}
	}, [virtualAppliance]);

	useEffect(() => {
		if (!product) {
			setProductAddons([]);
			setMaintenanceServices(initialValues.maintenanceServices);
			return;
		}

		const selectedProductModel = productModels.find((productModel) => { return productModel.id === product.modelId; });
		if (selectedProductModel) {
			setVirtualAppliance(selectedProductModel.virtualAppliance);
		}
		setProductAddons(product.productAddons);
		setMaintenanceServices(product.maintenanceServices);
	}, [productModels, product]);

	useEffect(() => {
		changeFormFieldInState('productAddons', productAddons);
		setExpiredProductAddons(productAddons.filter((addon) => { return new Date(addon.endDate) < new Date() && addon.isRenewed; }));
	}, [productAddons]);

	useEffect(() => {
		changeFormFieldInState('maintenanceServices', maintenanceServices);
	}, [maintenanceServices]);

	useEffect(() => {
		changeFormFieldInState('activationCode', generatedActivationKey);
	}, [generatedActivationKey]);

	// Addons
	const addAddon = useCallback(() => {
		setProductAddons([...productAddons, defaultAddon()]);
	}, [productAddons]);

	const changeAddon = useCallback((addon) => {
		setProductAddons(productAddons.map((a) => { return a.uuid === addon.uuid ? addon : a; }));
	}, [productAddons]);

	const removeAddon = useCallback((uuid) => {
		setProductAddons(productAddons.filter((addon) => { return addon.uuid !== uuid; }));
	}, [productAddons]);

	// Maintenance services
	const defaultMaintenanceService = useCallback(() => {
		const firstPlan = maintenancePlansOptions.find((option) => { return option.id === maintenanceServices[0].maintenancePlanId; });
		return {
			uuid: Utils.uuidv4(),
			maintenancePlanId: (firstPlan?.id ?? ''),
			hasSerenity: (firstPlan ? firstPlan.serenity === 'included' : false),
			duration: 36,
			saleValue: 0,
			isRenewal: true,
			quoteNumber: '',
		};
	}, [maintenancePlansOptions]);

	const changeMaintenanceService = useCallback((maintenanceService, index) => {
		setMaintenanceServices(maintenanceServices.map((service) => { return service.uuid === index ? maintenanceService : service; }));
	}, [maintenanceServices]);

	const changeProductModel = useCallback((value) => {
		const selectedProductModel = productModels.find((productModel) => {
			return productModel.id === value;
		});

		if (selectedProductModel) {
			setVirtualAppliance(selectedProductModel.virtualAppliance);
		}
	}, [productModels]);

	const addMaintenanceService = useCallback(() => {
		setMaintenanceServices([...maintenanceServices, defaultMaintenanceService()]);
	}, [maintenanceServices]);

	const removeMaintenanceRenewal = useCallback((index) => {
		setMaintenanceServices(maintenanceServices.filter((service) => { return service.uuid !== index; }));
	}, [maintenanceServices]);

	const copyToClipboard = (inputId) => {
		const copyText = document.getElementById(inputId);
		copyText.select();
		document.execCommand('copy');
	};

	const isFormValid = useCallback((product) => {
		if (product.maintenanceServices.length > 1) {
			if (!ProductValidationService.isSerialNumberValid(product.serialNumber) && !ProductValidationService.isDurationValid(maxMaintenanceExtension, maintenanceTotalDuration)) {
				setValidationMessage('That doesn\'t look like a correct serial number and the maximum number of years has been exceeded. Continue?');
				return false;
			}
			if (!ProductValidationService.isDurationValid(maxMaintenanceExtension, maintenanceTotalDuration)) {
				setValidationMessage('Maximum number of years has been reached. Continue?');
				return false;
			}
		} else if (!ProductValidationService.isSerialNumberValid(product.serialNumber) && !product.virtualAppliance) {
			setValidationMessage('That doesn\'t look like a correct serial number. Continue?');
			return false;
		}

		return true;
	}, [maintenanceTotalDuration, maxMaintenanceExtension]);

	const trySubmit = useCallback((product) => {
		if (isFormValid(product)) {
			return submit(product);
		}
	}, []);

	const submitWarningModal = useCallback((product) => {
		submit(product);
		setValidationMessage(null);
	}, []);

	return (
		<>
			<form id={id} className="form" onSubmit={handleSubmit(trySubmit)}>
				<fieldset className="border-0">
					<Row>
						<Col xs={8}>
							<h4 className="mt-4">Appliance</h4>
							<Row>
								<Col xs={3}>
									<Form.select
										label="Model"
										name="modelId"
										options={SelectOptionsRenderer.renderProductModel(productModels)}
										onValueChange={changeProductModel}
										emptyOption="Please select"
										required
									/>
								</Col>
								<Col xs={3}>
									<Form.text
										name="serialNumber"
										label="Serial number"
										disabled={virtualAppliance}
										required={!virtualAppliance}
										placeholder={virtualAppliance ? 'No serial attached' : ''}
									/>
								</Col>
								<Col xs={3}>
									<Form.text name="saleValue" label="Sale value" />
								</Col>
								<Col xs={3}>
									<Form.text name="osVersion" label="OS Version" disabled />
								</Col>
							</Row>

							<hr />
							{expiredProductAddons.length > 0 && <Button role="tertiary" className="float-end" onClick={() => { setShowExpiredAddons(true); }}>See expired add-ons</Button>}
							<h4 className="mt-4">Add-ons</h4>
							{productAddons.filter((addon) => { return !addon.isRenewed; }).length > 0 && <Row className="fw-bold mb-2">
								<Col xs={3}>Add-on name*</Col>
								<Col xs={2}>Start date</Col>
								<Col xs={3}>Duration*</Col>
								<Col xs={2}>Tier</Col>
								<Col>Start on activation</Col>
							</Row>}
							{productAddons.filter((addon) => { return !addon.isRenewed; }).map((addon, index) => {
								return (
									<ProductAddon key={addon.uuid} index={index} addon={addon} removeAddon={removeAddon} changeAddon={changeAddon} changeProp={changeFormFieldInState} />
								);
							})}
							<Row>
								<Col xs={'auto'} className="pt-2 add-note-btn-support" style={{ fontSize: '14px' }} onClick={addAddon}>
									<i className="fa fa-plus me-2" />Add new add-on
								</Col>
							</Row>

							<Row className="mt-3">
								<Col xs={12}>
									<Form.textarea label={virtualAppliance ? 'Notes*' : 'Notes'} id="notes" name="notes" />
								</Col>
							</Row>

							<hr />
							<h4 className="mt-4">Maintenance</h4>

							{!isEditing && <ProductMaintenancePlan
								index={0}
								maintenancePlansOptions={maintenancePlansOptions}
								maintenanceService={maintenanceServices[0]}
								isRenewal={false}
								change={changeFormFieldInState}
								changeMaintenanceService={changeMaintenanceService}
							/>}

							{isEditing && maintenanceServices.map((maintenanceService, index) => {
								return (
									<ProductMaintenancePlan
										key={maintenanceService.uuid}
										index={index}
										maintenancePlansOptions={maintenancePlansOptions}
										maintenanceService={maintenanceService}
										isRenewal={index > 0}
										change={changeFormFieldInState}
										changeMaintenanceService={changeMaintenanceService}
										deleteMaintenanceService={removeMaintenanceRenewal}
									/>
								);
							})}
							{isEditing && maintenanceServices && <Row>
								<Button role="tertiary" onClick={addMaintenanceService}>Extend maintenance</Button>
							</Row>}
						</Col>
						<Col xs={4}>
							<h4 className="mt-4">Activation details</h4>
							<Row>
								<Col xs={12}>
									<Form.select
										label="End-user"
										name="userId"
										options={SelectOptionsRenderer.renderCompaniesWithUsers(companiesWithEndUsers)}
										emptyOption="Not set"
										disabled={virtualAppliance}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className="mb-3">
										<Form.datepicker
											formName={formName}
											calendarClassName="activation-details__date-picker"
											id="activationDate"
											name="activationDate"
											label="Activation date"
											placeholderText="Not set"
											placement="left"
											initValue={product?.activationDate
												? moment(new Date(product.activationDate), 'DD/MM/YYYY').toDate()
												: null}
											autoComplete="off"
											changeProp={changeFormFieldInState}
											disabled={virtualAppliance && !isEditing}
										/>
									</div>
								</Col>
							</Row>
							{virtualAppliance && <Row>
								<Col xs={12}>
									<div className="float-end">
										<Tooltip text="Copy activation key" placement="top" trigger="click">
											<Button className="p-0 me-2" role="tertiary" onClick={() => { return copyToClipboard('activationCode'); }}>
												<Icon name="fa fa-copy" color="black" />
											</Button>
										</Tooltip>
										{!isEditing && <Button className="p-0 me-2" role="tertiary" onClick={() => { return getActivationCode(); }}>
											<Icon name="fa fa-sync" color="black" />
										</Button>}
									</div>
									<Form.text
										id="activationCode"
										name="activationCode"
										label="Activation Code"
										value={product?.activationCode ?? ''}
										placeholder="Not set"
										readOnly
									/>
								</Col>
							</Row>}
						</Col>
					</Row>
					<Row>
						<Button disabled={isSendingRequest} type="submit">
							{isSendingRequest ? <Icon name="fa fa-spinner fa-spin" /> : isEditing ? 'Update' : 'Create'}
						</Button>
					</Row>
				</fieldset>
			</form>

			<WarningModal
				showWarningModal={!!validationMessage}
				content={validationMessage}
				closeWarningModal={() => { setValidationMessage(null); }}
				onSubmit={handleSubmit(submitWarningModal)}
			/>

			<Modal
				enforceFocus={false}
				show={showExpiredAddons}
				onHide={() => { setShowExpiredAddons(false); }}
				content={<ExpiredProductAddons addons={expiredProductAddons} />}
				title="Expired add-ons"
				size="lg"
			/>
		</>);
};

const mapStateToProps = (state) => {
	const selectedProduct = state.products.selectedProduct;
	const maintenanceTotalDuration = selectedProduct?.maintenanceServices?.slice(1).reduce(
		(acc, maintenanceExtension) => {
			acc += parseInt(maintenanceExtension.duration);
			return acc;
		},
		parseInt(selectedProduct?.maintenanceServices[0].duration)
	);

	return {
		generatedActivationKey: state.products.activationKey,
		productModels: state.productModels.models,
		companiesWithEndUsers: state.companies.companiesWithEndUsers,
		maintenancePlansOptions: state.maintenancePlansOptions.maintenancePlansOptions.currentlySelling,
		maintenanceTotalDuration: maintenanceTotalDuration,
		maxMaintenanceExtension: getSettingValue(state.settings.data, 'maxMaintenanceExtension') ?? 36,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getActivationCode: () => { return dispatch(getActivationCode()); },
		setSelectedProduct: (data) => { return dispatch(getProductSuccess(data)); },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);

ProductForm.propTypes = {
	productModels: PropTypes.array,
	companiesWithEndUsers: PropTypes.array,
	maintenancePlansOptions: PropTypes.array,
	id: PropTypes.string.isRequired,
	formName: PropTypes.string.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSendingRequest: PropTypes.bool,
	getActivationCode: PropTypes.func.isRequired,
	changeFormFieldInState: PropTypes.func.isRequired,
	submit: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	product: PropTypes.object,
	initialValues: PropTypes.object
};
