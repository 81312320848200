import moment from 'moment';

class MaintenanceStatusFormatter {
	static maintenanceStatus(maintenanceServices) {
		let maintenanceStatus = 'Pending';
		if ( !maintenanceServices || maintenanceServices.length === 0 ) {
			return status;
		}

		maintenanceServices.every((maintenanceService) => {
			if (!maintenanceService.expiresOn) {
				return true;
			}

			let maintenanceStatusFormatted = moment(maintenanceService.expiresOn).add(1, 'day').format('YYYY-MM-DD');
			if ( moment().isBefore(maintenanceStatusFormatted) ) {
				maintenanceStatus = 'Active';
				return false;
			} else {
				maintenanceStatus = 'Expired';
			}
		});

		return maintenanceStatus;
	}

	static setExpiresOnBadge(status) {
		switch (status) {
			case 'Active':
				return 'secondary';
			case 'Expired':
				return 'danger';
		}
	}

	static setStyle(status) {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Pending':
				return 'warning';
			case 'Expired':
				return 'danger';
		}
	}

	static setStyleAddon(status) {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Terminated':
				return 'danger';
		}
	}

	static setTooltipInformation(expiresOn) {
		let tooltipInformation = '';

		if ( !expiresOn ) {
			tooltipInformation = 'Pending. Product not activated yet!';
		} else {
			let expiresOnFormatted = moment(expiresOn).format('YYYY-MM-DD');
			if ( moment().isBefore(expiresOnFormatted) ) {
				tooltipInformation = `Expires on ${ expiresOnFormatted }`;
			} else {
				tooltipInformation = `Expired on ${ expiresOnFormatted }`;
			}
		}

		return tooltipInformation;
	}

	static showTooltipDate(expiresOn) {
		if ( !expiresOn ) {
			return 'Pending. Product not activated yet!';
		}

		if ( moment().isBefore(moment(expiresOn, 'DD/MM/YYYY')) ) {
			return `Expires on ${ expiresOn }`;
		} else {
			return `Expired on ${ expiresOn }`;
		}
	}
}

export default MaintenanceStatusFormatter;
