import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@syneto/compass-react';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../../data/notifications/actions';
import { deleteProductAddons } from '../../../../../services/products';
import { getJob } from '../../../../../services/jobs';
class DeleteModalClass extends React.Component {
	render() {
		const { addons, onHide } = this.props;

		return (
			<Modal
				show={true}
				onHide={onHide}
				title={this.title(addons)}
				content={this.content(addons)}
				actions={addons.length ?
					{
						primary: {
							label: 'Delete',
							onClick: this.onSubmit
						},
						secondary: {
							label: 'Cancel',
							onClick: onHide
						}
					} :
					{
						primary: {
							label: 'OK',
							onClick: onHide
						}
					}
				}
			/>
		);
	}

	title = (addons) => {
		if (addons.length > 1) {
			return 'Delete add-ons';
		}

		return 'Delete add-on';
	};

	content = (addons) => {
		if (addons.length === 1) {
			return <p className="text-value">You are going to delete a product add-on. Are you sure?</p>;
		}

		return addons.length > 1 ?
			'You are going to delete several product add-ons. Are you sure?' :
			'You have not selected any product add-on for deletion.';
	};

	onSubmit = () => {
		const { addons, onHide, afterUpdateCallback } = this.props;

		let payload = addons.map((obj) => { return obj.id; } );

		deleteProductAddons(payload)
			.then((updateResponse) => {
				const jobId = updateResponse?.data?.jobId;
				
				// function to poll the job status every 3 seconds
				function pollJobStatus() {
					getJob(jobId)
						.then((jobResponse) => {
							if (jobResponse.data.status.toLowerCase() === 'succeeded') {
								afterUpdateCallback(); // call the callback when succeeded
							} else {
								setTimeout(pollJobStatus, 1000); // poll again in 1 second
							}
						})
						.catch((error) => {
							setTimeout(pollJobStatus, 1000); // retry polling in case of error
						});
				}

				// start polling the job status
				pollJobStatus();

				onHide();
				this.props.pushSuccessfulNotification(
					payload.length > 1 ? 'Add-ons deleted successfully.' : 'Add-on deleted successfully.'
				);
			})
			.catch((error) => {
				this.props.pushDangerNotification(
					payload.length > 1 ? 'Could not delete add-ons.' : 'Could not delete add-on.'
				);
			});
	};
}

const mapStateToProps = ({ productMaintenanceService }) => {
	return {
		success: productMaintenanceService.success,
		error: productMaintenanceService.error
	};
};

export const DeleteModal = connect(mapStateToProps, {
	pushSuccessfulNotification,
	pushDangerNotification

})(DeleteModalClass);

DeleteModal.propTypes = {
	onHide: PropTypes.func.isRequired,
	addons: PropTypes.array,
	afterUpdateCallback: PropTypes.func.isRequired
};
