import React, { useEffect } from 'react';
import { Field, change, touch } from 'redux-form';
import { Form, DatePicker } from '@syneto/compass-react';
import { connect } from 'react-redux';

const FormDatePicker = (props) => {
	
	return (
		<Field {...props} component={renderField} />
	);
};

const renderField = (props) => {
	const { label, icon, required, id, name, className, style, changeProp, touchOnBlur, autoComplete, initValue, onValueChange } = props;
	const { placeholder, inputRef, disabled } = props;
	const { meta: { touched, error, warning } } = props;

	useEffect(() => {
		if (initValue) {
			changeProp(moment(initValue).format('DD/MM/YYYY'));
		}
	}, [initValue]);

	const updateValue = (newValue) => {
		if (newValue) {
			changeProp(moment(newValue).format('DD/MM/YYYY'));
			if (onValueChange) {
				onValueChange(newValue);
			}
		} else {
			changeProp(null);
			if (onValueChange) {
				onValueChange(null);
			}
		}
	};

	return (
		<Form.Group className='mb-3' controlId={ id }>
			{ label ? <Form.Label>{ label }{ required ? '*' : null } { icon ? icon : null }</Form.Label> : null }


			<Form.Control
				type='hidden'
				isInvalid={touched && error}
				name={ name }
			/>

			<DatePicker
				ref={ inputRef }
				placeholderText={placeholder}
				style={ style }
				className={ `form-control ${className} ${touched && error ? 'is-invalid': ''}`}
				isInvalid={touched && error}
				selected={initValue ? moment(initValue).toDate() : null}
				onChange={(newValue) => {
					updateValue(newValue);	
				}}
				autoComplete={autoComplete}
				onBlur={touchOnBlur}
				disabled={disabled}
			/>

			{ touched && warning && <span>{ warning }</span> }

			<Form.Control.Feedback type="invalid">
				{ error }
			</Form.Control.Feedback>
		</Form.Group>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	if (!ownProps.formName || !ownProps.name) {
		return {};
	}

	return {
		changeProp: (value) => { dispatch(change(ownProps.formName, ownProps.name, value)); },
		touchOnBlur: () => { dispatch(touch(ownProps.formName, ownProps.name)); }
	};
};

export default connect(null, mapDispatchToProps)(FormDatePicker);
