import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon, Table, Tooltip } from '@syneto/compass-react';
import MaintenanceStatusFormatter from '../../../../helpers/MaintenanceStatusFormatter';
import DateFormatter from '../../../../helpers/DateFormatter';
import { ActivationPdf } from '../OperationsProducts/generatePdf/ActivationPdf';
import { MaintenancePlanPdf } from '../OperationsProducts/generatePdf/MaintenancePlanPdf';
import Preloader from '../../../../components/preloader';
import { handleSort } from '../../../../helpers/TableUtilities';
import { useNavigate } from 'react-router';

const processTableData = (products) => {
	return products.map((product) => {
		return {
			activationKey: product?.activationKey?.productKey ?? '',
			activePlan: product?.maintenanceServices[0]?.activePlan?.synetoInternalName,
			id: product.id,
			notes: product?.notes ?? '',
			status: MaintenanceStatusFormatter.maintenanceStatus(product?.maintenanceServices) ?? '',
			customerFacingName: product.maintenanceService?.plan?.customerFacingName ?? '',
			serialNumber: product.serialNumber ?? 'N/A',
			model: product?.modelName ?? '',
			plan: product.planName ?? '',
			hasSerenity: product?.hasSerenity ?? false,
			maintenanceValidity: product?.validity ?? '',
			customer: product?.customer ?? '',
			expirationDate: DateFormatter.formatDateToSpecificFormat(product?.maintenanceServices[product?.maintenanceServices.length - 1] ?? {}, 'expiresOn', 'DD/MM/YYYY') ?? '',
			activationDate: DateFormatter.formatDateToSpecificFormat(product, 'activationDate', 'DD/MM/YYYY') ?? ''
		};
	});
};

export const ProductsTable = (props) => {
	const { loading, products, pagination, queryParams, setItemToRemove, setQueryParams } = props;

	const navigate = useNavigate();

	const handleTableChange = (type, { page, sizePerPage, searchText, sortOrder, sortField, data }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
			case 'search': {
				debouncedHandleSearch(searchText);
				return;
			}
			case 'sort': {
				handleSort(sortOrder, sortField, data);
				return;
			}
		}
	};

	const handlePagination = (page, sizePerPage) => {
		const start = (page - 1) * sizePerPage;

		setQueryParams({
			...queryParams,
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleSearch = (value) => {
		setQueryParams({
			...queryParams,
			searchFor: value
		});
	};

	const debounce = (func, delay) => {
		let timerId;
		return function() {
			const context = this;
			const args = arguments;
			clearTimeout(timerId);
			timerId = setTimeout(() => {
				func.apply(context, args);
			}, delay);
		};
	};

	const debouncedHandleSearch = debounce(handleSearch, 500);

	const renderCellActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { navigate(`/maintenance-service/product/${row.id}`); }}>
					Edit
				</Button>
				<Button role="tertiary" onClick={(e) => { setItemToRemove(new Array(row)); e.stopPropagation(); }}>
					Delete
				</Button>
			</>
		);
	};

	const renderStatus = (cell, row) => {
		return (
			<Tooltip text={MaintenanceStatusFormatter.showTooltipDate(row.expirationDate)} placement="top">
				<Badge appearance={MaintenanceStatusFormatter.setStyle(row.status)}>
					{row.status}
				</Badge>
			</Tooltip>
		);
	};

	const renderSerenity = (cell, row) => {
		return (
			row.hasSerenity ? <Badge appearance="success"><Icon name="fa fa-check" /></Badge>
				: <Badge appearance="danger"><Icon name="fa fa-times" /></Badge>
		);
	};

	const renderNotesAndActivationKeyPdf = (cell, row) => {
		return (
			<>
				{
					row.notes &&
					<span>
						<Tooltip text={row.notes} placement="top">
							<span>
								<Icon name="fa fa-comment-o" aria-hidden="true" style={{ cursor: 'pointer' }} />
							</span>
						</Tooltip>
					</span>
				}

				{
					row.activationKey &&
					<ActivationPdf
						activationKey={row.activationKey}
						createdAt={row.createdAt}
					/>
				}

				<MaintenancePlanPdf
					expirationDate={row.expirationDate}
					maintenancePlan={row.customerFacingName}
					serialNumber={row.serialNumber}
				/>
			</>
		);
	};

	const emptyTableState = () => {
		return loading 
			? <Preloader dataIsLoading={true} />
			: 'No products found';
	};

	return (
		<div className="p-4 bg-white">
			<Table
				data={processTableData(products)}
				keyField="id"
				remote
				pagination={{
					sizePerPageList: [15, 30, 60, 100, 200],
					sizePerPage: pagination?.itemsPerPage,
					showTotal: true,
					page: pagination?.currentPage,
					totalSize: pagination?.totalItems
				}}
				selectable="multiple"
				search={{ defaultSearch: queryParams.searchFor }}
				rowEvents={{ onClick: (e, row) => { return navigate(`/maintenance-service/product/${row.id}`); } }}
				actions={[{
					label: 'Delete selected',
					onClick: (selected) => { return setItemToRemove(selected); }
				}]}
				onTableChange={handleTableChange}
				noDataIndication={emptyTableState()}
			>
				<Table.Col field="notes" align="left" width="70px" renderCell={renderNotesAndActivationKeyPdf} />
				<Table.Col field="status" align="left" renderCell={renderStatus} sort>Status</Table.Col>
				<Table.Col field="serialNumber" align="left" sort oneline width="130px">Serial number</Table.Col>
				<Table.Col field="model" align="left" sort>Model</Table.Col>
				<Table.Col field="plan" align="left" sort>Plan</Table.Col>
				<Table.Col field="hasSerenity" align="center" renderCell={renderSerenity}>Serenity</Table.Col>
				<Table.Col field="maintenanceValidity" align="left">Validity</Table.Col>
				<Table.Col field="customer" align="left" sort>Customer</Table.Col>
				<Table.Col field="activationDate" align="left">Activated on</Table.Col>
				<Table.Col align="center" renderCell={renderCellActions}>Actions</Table.Col>
			</Table>
		</div>
	);
};

ProductsTable.propTypes = {
	products: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
	queryParams: PropTypes.object.isRequired,
	setQueryParams: PropTypes.func.isRequired,
	setItemToRemove: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};
